import "bootstrap/dist/css/bootstrap.min.css";
import "./css/bootstrap.icons.css";
import "./css/style.css";
import 'react-toastify/dist/ReactToastify.css';

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getAccessToken, getRefreshToken } from "./utils/common";
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login";
import Dev from "./pages/adm/Dev";
import Myprofile from "./pages/adm/Myprofile";
import Article from "./pages/adm/Article";
import User from "./pages/adm/User";
import NotFound from "./pages/NotFound";
import Main from "./pages/Main";
import Manager from "./pages/adm/Manager";
import Codes from "./pages/adm/Codes";
import LiveUser from "./pages/adm/LiveUser";
import Grade from "./pages/adm/Grade";
import Analyzer from "./pages/adm/Analyzer";
import Home from "./pages/adm/Home";

const App = () => {
    if (getAccessToken() && getRefreshToken()) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main />}></Route>
                    <Route path="*" element={<NotFound />}></Route>
                    <Route path="/adm" element={<Home />}></Route>
                    <Route path="/adm/myprofile" element={<Myprofile />}></Route>
                    <Route path="/adm/grade" element={<Grade />}></Route>
                    <Route path="/adm/user" element={<User />}></Route>
                    <Route path="/adm/manager" element={<Manager />}></Route>

                    <Route path="/adm/codes" element={<Codes />}></Route>
                    <Route path="/adm/analyzer/:gbn" element={<Analyzer />}></Route>
                    <Route path="/adm/liveuser" element={<LiveUser />}></Route>

                    <Route path="/adm/article/:board_id" element={<Article />}></Route>
                </Routes>
                <ToastContainer />
            </BrowserRouter>
        );
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main />}></Route>
                    <Route path="*" element={<NotFound />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                </Routes>
            </BrowserRouter>
        );
    }
};

export default App;
